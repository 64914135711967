export default defineNuxtRouteMiddleware(async (to) => {
  const params = to.params as {
    permalink: string;
  };

  const { data } = await useGetArticleDetailByPermalink(params.permalink, 300);

  const { setBreadcrumbs } = useBreadcrumbs();
  setBreadcrumbs([
    {
      to: '/public/article/dietqa',
      text: 'ダイエットQ&A',
    },
    {
      text: data.value!.title,
    },
  ]);
});
